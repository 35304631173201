import React from "react";
import PropTypes from "prop-types";
import { FormattedHTMLMessage, injectIntl } from "gatsby-plugin-intl";
import { Card } from "react-bootstrap";
import CheckCircle from "../images/check_circle.svg";
const RequestSubmitted = props => {
  return (
    <Card
      style={{
        maxWidth: "568px",
        margin: "18px auto 32px",
        border: "1px solid #DCDDDF",
        boxShadow:
          "0px 2px 4px rgba(22, 31, 42, 0.07), 0px 2px 4px rgba(22, 31, 42, 0.06), 0px 4px 6px rgba(22, 31, 42, 0.1)",
        borderRadius: "6px"
      }}
    >
      <Card.Body style={{ padding: "32px" }}>
        <Card.Title
          style={{
            color: "white",
            padding: "12px",
            minHeight: "48px",
            background: "#01A7E5",
            boxShadow:
              "0px 1px 1px rgba(22, 31, 42, 0.1), 0px 2px 2px rgba(22, 31, 42, 0.08), 0px 1px 3px rgba(22, 31, 42, 0.1)",
            borderRadius: "4px",
            textAlign: "center",
            fontWeight: "700"
          }}
        >
          <FormattedHTMLMessage
            id="form.success.request-submitted"
            defaultMessage="Request submitted"
            description="Request submitted header"
          />
          <span style={{ marginLeft: "8px" }}>
            <img src={CheckCircle} alt="" />
          </span>
        </Card.Title>
        <div
          style={{
            background: "#ccf2ff",
            border: "1px solid #ccf2ff",
            boxSizing: "border-box",
            borderRadius: "6px",
            padding: "19px",
            margin: "auto",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "16.94px"
          }}
        >
          <Card.Text
            style={{
              maxWidth: "58ch",
              textAlign: "justify",
              margin: "auto",
              fontSize: "14px"
            }}
          >
            <FormattedHTMLMessage
              id="form.success.body"
              defaultMessage="Your request <strong>ID number {requestId}</strong> has been submitted."
              description="Request submitted message with requestID"
              values={{
                requestId: props.requestId
              }}
            />
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

RequestSubmitted.propTypes = {
  requestId: PropTypes.string
};

export default injectIntl(RequestSubmitted);
