import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { navigate } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import Layout from "../layouts/main";
import SEO from "../components/seo";
import RequestSubmitted from "../components/requestSubmitted";

const Success = props => {
  const intl = useIntl();
  let requestId = "";
  let currentForm = "";
  // let requestTypes = [];
  // Wrap the require in check for window
  if (typeof window !== `undefined`) {
    if (window.history.state !== null) {
      requestId = window.history.state.data.rootId;
      currentForm = window.history.state.formType;
      // requestTypes = window.history.state.requestTypes;
    } else {
      // If we don't have any state passed in, go back to the landing page.
      navigate("/");
    }
  }

  return (
    <Layout
      location={props.location}
      currentForm={currentForm}
      top={
        <>
          <Row className="mb-5">
            <Col>
              <h1>{intl.formatMessage({ id: "header.title" })}</h1>
              {/* <FormattedHTMLMessage
                id="header.description"
                values={{ item: msg => ({ msg }) }}
              /> */}
            </Col>
          </Row>
        </>
      }
      center={
        <>
          <SEO title="Success" />
          <Row>
            <Col>
              <RequestSubmitted requestId={requestId} />
            </Col>
          </Row>
        </>
      }
    />
  );
};

Success.propTypes = {
  location: PropTypes.object
};

export default Success;
